body {
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.2) 1px, transparent 1px),
    linear-gradient(#ffbf00, #ffbf00),
    linear-gradient(90deg, #ffbf00, #ffbf00);
  background-size: 50px 50px, 50px 50px, 100% 100%, 100% 100%;
  background-position: 0 0, 0 0, 0 0, 0 0;
  font-family: PingFang, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

a {
  color: #007bff;
  text-decoration: none;
}

.title {
  background-color: #007bff;
  color: #fff;
  padding: 0px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 5px;
  border-radius: 10px;
  line-height: 1.5;
}

.title-icon-img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  vertical-align: middle;
}

a:hover {
  text-decoration: underline;
}

.ball-selector {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 20px;
}

.ball-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
  max-width: 450px; /* Set the max-width to limit each row to one row of balls */
  margin: 0 auto; /* Center align the ball-container */
}

.ball-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
}

.ball {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 2px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  background-color: #f5b800;
  background-image: linear-gradient(#f5b800, #ffa700);
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25); */
  /* transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
}

.ball-icon-img {
  width: inherit;
}

.ball.selected {
  background-color: #d84315;
  background-image: linear-gradient(#d84315, #bf360c);
  box-shadow: none;
}

/* .selected-numbers {
  margin-top: 20px;
} */

/* .selected-balls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

.selected-reuslt {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 20px;
}

.selected-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background-color: #ffa700;
  background-image: linear-gradient(#ffa700, #f5b800);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* .selected-ball:hover,
.selected-ball:focus {
  transform: scale(1.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  } */
  
  .submit-button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #d84315;
  background-image: linear-gradient(#d84315, #bf360c);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .submit-button:hover,
  .submit-button:focus {
  transform: scale(1.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  }

  .clear-button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #007bff;
  background-image: linear-gradient(#007bff, #0069d9);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  .clear-button:hover,
  .clear-button:focus {
  transform: scale(1.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  }
  
  .button-container {
  display: flex;
  }
  .header {
  background-color: #007bff;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  }
  
  .footer {
  background-color: #f5f5f5;
  color: #333;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  }
  .money-preview {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    padding: 20px;
    text-align: center;
  }

  .add-column-botton,.clear-column-botton,.clear-all-column-botton {
    display: inline-flex;
    align-items: center;
    text-align: center;
    border: 0px;
    border-radius: 15px;
    font-size: 20px;
    margin: 5px 5px;
    font-weight: bold;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.column-action-botton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-column-botton:hover,
.add-column-botton:focus {
  transform: scale(1.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}



.add-column-botton {
  color: black;
  background-color: #8EE5EE;
  background-image: linear-gradient(#8EE5EE, #8EE5EE);
}

.clear-column-botton {
  color: white;
  background-color: #EE82EE;
  background-image: linear-gradient(#EE82EE, #EE82EE);
}

.clear-all-column-botton {
  color: white;
  background-color: #ee8282;
  background-image: linear-gradient(#ee8282, #ee8282);
}

.clear-column-botton:hover,
.clear-column-botton:focus{
  transform: scale(1.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.clear-all-column-botton:hover,
.clear-all-column-botton:focus{
  transform: scale(1.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.column-group-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.column-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  padding: 10px;
  background-color: #00BFFF;
  height: 300px; /* 根据需要调整列的高度 */
  border-radius: 20px 20px 20px 20px; /* 添加圆角 */
}


  
  .money-preview h3 {
    color: #ffb700;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .money-preview p {
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  .order-area {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding: 5px;
    text-align: center;
  }

  .order-area .order-area-row {
    display: inline-block;
    text-align: left;
  }

  .order-area-row-not-inpt {
    text-align: center !important;
  }
  
  .order-area h3 {
    color: #ffb700;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .order-area input[type="number"],
  .order-area input[type="text"]{
    appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ffb700;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    margin: 10px;
    padding: 5px;
    text-align: center;
    width: 80px;
  }
  
  .order-area input[type="number"]:focus,
  .order-area input[type="text"]:focus {
    outline: none;
  }
  
  .order-area button {
    background-color: #ffb700;
    border: none;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px 20px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .order-area button:hover {
    background-color: #d29200;
  }

  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* transparent black */
  }
  
  .three-ball-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .three-ball-selector input[type="text"],.input-three-ball-area input[type="tel"] {
    appearance: none;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    padding: 5px;
    text-align: center;
    width: calc(100% / 3 - 10px);
    margin-bottom: 10px;
    max-width: 50px;
  }

  
  
  /* .three-ball-selector input[type="number"]:focus {
    outline: none;
    border-color: #007bff;
  } */
  
  .three-ball-add-btn {
    background-color: #007bff;
    border: none;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .three-ball-add-btn:hover {
   background-color: #0069d9;
  }

  .input-three-ball-area {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }


  .three-ball-buttons {
    display: flex;
    justify-content: center;
    gap: 10px; /* 設定按鈕之間的間距 */
    margin-top: 20px; /* 設定按鈕區塊與選號區塊之間的間距 */
  }


  .three-ball-selector-btn {
    border: none;
    border-radius: 20px;
    color: black;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    transition: background-color 0.3s ease;
    flex: 1;
    }
    
    .three-ball-selector-btn.add {
    background-color: #8EE5EE;
    }
    
    .three-ball-selector-btn.clear {
    background-color: white;
    }
    
    .therr-ball-selector-unit{
      font-size: 20px;
      font-weight: bold;
      padding: 5px 20px;
    }
    
  
  .selected-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }
    

  .three-ball-selected-numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .three-ball-selected-numbers div {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    
  }


  .three-ball-selected-area {
    margin-top: 60px;
  }

  .none-loading {
    display: none;
  }

  .news-ticker {
    text-align: center;
    background-color: #333;
    color: white;
    font-size: 20px;
    font-weight: bold;
    line-height: 2;
    min-height: 40px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .news-ticker span {
    text-align: center;
    display: inline-block;
    max-width: 83%;
    word-break: break-all;
  }


  @media screen and (max-width: 200px) {
    .input-three-ball-area {
    flex-direction: row;
    }
    }